<template>
      
  <section class="dashboard">

    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/create')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{$options.label }}</h3>
        
<!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <a href="" class="action-link"><span></span>Vista general</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
-->                
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listando {{$options.label}}</h4>
            <div class="table-responsive">


              <table class="table">
                <thead>
                  <tr>
                    <th><input type="checkbox"></th>
                    <th> ID </th>
                    <th> Nombre </th>
                    <th> Acciones </th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-if="listing">
                    <td colspan="10">
                      <b-skeleton-table
                        :rows="3"
                        :columns="4"
                        :table-props="{ bordered: true, striped: true }"
                        
                      ></b-skeleton-table>
                    </td>
                  </tr>


                  <tr v-for="(item, index) in items" :key="index">
                    <td><input type="checkbox"></td>
                    <td>{{item.id}}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      <router-link class="action-link" :to="link('/update/'+item.id)"><i class="mdi mdi-square-edit-outline"></i></router-link>
                      <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import api from '@/util/api.js';


export default {
  routeName:'amenities',
  label:'Amenidades',
  name: 'listAmenities',
  components: {
  },
  data() {
    return {
      items:[],
      listing:false,
     
    };
  },


  methods: {

    getItems() {

      this.listing=true;
      
      api.get(this.me('/')).then(response => {
        this.items = response.data;

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },


    deleteMe(id,index){





      if(confirm('¿Desea eliminar el elemento?')) {

              api.delete(this.me(id)).then(()=>{

                this.items.splice(index,1);
                this.$forceUpdate();
                
              
              }).catch(error=>{

                console.log(error);
                
              });

        }

      }
          

      
  },

  mounted(){

    
    this.getItems();
  }

}



</script>


